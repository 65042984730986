
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import Header from '../components/header/Header.vue';
import Backdrop from '../components/home/Backdrop.vue';
import Anime from '../types/Anime';
import SubmitResponse from '../types/SubmitResponse';
import isUser from '../context/user';
import User from '../types/User';

export default defineComponent({
  components: { Header, Backdrop },
  setup() {
    const route = useRoute();

    const userCheck = ref(false);
    const user = ref<User | null>(null);
    const userAnimeInLists = ref<Anime[] | null>(null);
    const listValue = ref<string | null>(null);
    const scoreValue = ref<string | number | null>(null);
    const episodeValue = ref<string | number | null>(null);
    const submitMessage = ref<string | null>(null);
    const submitResponse = ref<SubmitResponse | null>(null);

    isUser(userCheck);
    if (userCheck.value) {
      user.value = JSON.parse(localStorage.getItem('user') || '{}');
    }

    const anime = ref<Anime | null>(null);

    const fetchSingleAnime = async () => {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_BACKEND_URL}/single/${route.params.id}`
        );
        if (!response.ok) {
          throw Error('No data available');
        }
        const json = await response.json();
        const jsonObj = await JSON.parse(json);
        anime.value = jsonObj;
      } catch (error) {
        error.value = error.message;
        console.log('Error: ', error.value);
      }
    };
    fetchSingleAnime();

    const checkAnime = async () => {
      try {
        if (user.value?.access_token) {
          const response = await fetch(
            `${process.env.VUE_APP_BACKEND_URL}/check-lists/${user.value?.access_token}`
          );
          if (!response.ok) {
            throw Error('No data available');
          }
          const json = await response.json();
          const jsonObj = await JSON.parse(json);
          userAnimeInLists.value = jsonObj.data;
          if (jsonObj.data.filter((el: any) => el.node.id.toString() === route.params.id)[0]) {
            const animeShow = jsonObj.data.filter(
              (el: any) => el.node.id.toString() === route.params.id
            )[0];

            listValue.value = animeShow.list_status.status;
            scoreValue.value = animeShow.list_status.score;
            episodeValue.value = animeShow.list_status.num_episodes_watched;
          }
        }
      } catch (error) {
        error.value = error.message;
        console.log('Error: ', error.value);
      }
    };

    checkAnime();

    const toggleSynopsis = () => {
      const shortSynopsis = document.querySelector('.short-synopsis') || null;
      const fullSynopsis = document.querySelector('.full-synopsis') || null;

      if (fullSynopsis && shortSynopsis && fullSynopsis.classList.contains('hidden')) {
        shortSynopsis.classList.add('hidden');
        fullSynopsis.classList.remove('hidden');
      } else if (fullSynopsis && shortSynopsis && shortSynopsis.classList.contains('hidden')) {
        shortSynopsis.classList.remove('hidden');
        fullSynopsis.classList.add('hidden');
      }
    };

    function onChangeList(e: any) {
      listValue.value = e.target.value;
    }
    function onChangeScore(e: any) {
      scoreValue.value = e.target.value;
    }
    function onChangeEpisode(e: any) {
      episodeValue.value = e.target.value;
    }

    const submitAnimeInfo = async () => {
      try {
        submitMessage.value = null;
        if (listValue.value === 'delete') {
          const response = await fetch(
            `${process.env.VUE_APP_BACKEND_URL}/user-anime/delete/${route.params.id}/${user.value?.access_token}`
          );
          if (!response.ok) {
            throw Error('No data available');
          }
          const json = await response.json();
          const jsonObj = await JSON.parse(json);
          submitResponse.value = jsonObj;
          submitMessage.value = 'Succesfully deleted.';
        } else if (listValue.value && scoreValue.value && episodeValue.value) {
          const response = await fetch(
            `${process.env.VUE_APP_BACKEND_URL}/user-anime/${route.params.id}/${user.value?.access_token}/${listValue.value}/${scoreValue.value}/${episodeValue.value}`
          );
          if (!response.ok) {
            throw Error('No data available');
          }
          const json = await response.json();
          const jsonObj = await JSON.parse(json);
          submitResponse.value = jsonObj;

          if (
            submitResponse.value?.status === listValue.value &&
            submitResponse.value?.score.toString() === scoreValue.value.toString() &&
            Number(episodeValue.value) >= submitResponse.value?.num_episodes_watched
          ) {
            submitMessage.value = 'Successfully updated list.';
          } else {
            submitMessage.value = 'Error updating list.';
          }
        } else {
          submitMessage.value = 'Please enter values for all options.';
        }
      } catch (error) {
        error.value = error.message;
        console.log('Error: ', error.value);
      }
    };

    return {
      anime,
      toggleSynopsis,
      onChangeList,
      onChangeScore,
      onChangeEpisode,
      listValue,
      scoreValue,
      episodeValue,
      submitAnimeInfo,
      submitMessage,
      user
    };
  }
});
